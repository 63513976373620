.stepper-head:hover {
    background-color: inherit !important;
}

.stepper:not(.stepper-vertical) .stepper-step {
    flex: auto;
    height: 8.5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:last-child) .stepper-head:after {
    flex: 1;
    height: 2px;
    width: 100%;
    margin-left: 0.5rem;
    background: $primary;
}

.stepper:not(.stepper-vertical) .stepper-step.disabled:not(:last-child) .stepper-head:after {
    background: $gray;
}

.stepper:not(.stepper-vertical) .stepper-step.disabled:not(:last-child) .stepper-head:before {
    background: $gray;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:first-child) .stepper-head:before {
    flex: 1;
    height: 2px;
    width: 100%;
    margin-right: 0.5rem;
    content: "";
    background-color: $primary;
}

.stepper:not(.stepper-vertical) .stepper-step.disabled:last-child .stepper-head:before {
    background-color: $gray;
}

.stepper-head-label {
    position: absolute;
    color: #212529;
    width: auto;
    top: 32px;
    white-space: nowrap;
}

li.stepper-step.inactive {
    .stepper-head .stepper-head-icon {
        background-color: $gray-lighter;
    }
}

li.stepper-step.disabled {
    .stepper:not(.stepper-vertical) .stepper-step:not(:last-child) .stepper-head:after {
        background: $gray;
    }

    .stepper-head-icon {
        background-color: $gray;
    }
}

li.stepper-step.inactive.first {
    // need to set margin left
}

li.stepper-step.inactive.last {
    // need to set margin right
}