html, body {
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

@import 'components/select';
@import 'components/button';
@import 'components/form';
@import 'components/table';
@import 'components/stepper';
@import 'components/utility';
@import 'components/print';
@import 'components/scrollbar';