// avatar
.avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    overflow: hidden;

    img {
        vertical-align: top;
    }

    &-md {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        overflow: hidden;
    }

    &-xl {
        width: 120px;
        height: 120px;
    }

    &-lg {
        width: 70px;
        height: 70px;
        line-height: 65px;
        text-align: center;
        overflow: hidden;
        position: relative;
        border: 5px solid $light;

        i {
            color: $primary;
            font-size: 1.5rem;
        }
    }
}

.separator {
    display: inline-block;
    text-align: center;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $gray-light;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

// cart count
.cart-item {
    position: absolute;
    top: 0.5rem;
    margin-left: -0.5rem;
    width: 1rem;
}

// stepper
.survey-stepper {
    width: 2rem;
    height: 0px;
    display: inline-block !important;
    border: 0.25rem solid $gray;
    border-radius: 0.25rem;

    &.active {
        opacity: 0.4;
    }
}


.text-wrapper {
    width: auto;
}

@include media-breakpoint-down(xl) {
    .text-wrapper {
        width: 240px;
    }
}

@media print {
    .col-12 {
        width: 100%;
    }
    @page {
        // reset page margin to none
        size: auto;
        margin: 0mm;
        height: 100%;
    }

    .no-print {
        display: none !important;
    }

    .print {
        display: block !important;
    }
}

.status {
    &.bg-success, &.bg-gray-light, &.bg-warning, &.bg-danger {
        opacity: 0.2;
    }
}