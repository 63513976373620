.scrollbar {
    scrollbar-color: $secondary-light #f5f5f5;
    scrollbar-width: thin;
  }

  .scrollbar-primary::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  
  .scrollbar-primary::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(lighten($secondary-light, 10%)),
      color-stop(99%, darken($secondary-light, 10%)),
      to(darken($secondary-light, 10%))
    );
    background-image: -webkit-linear-gradient(
      bottom,
      lighten($secondary-light, 10%) 0%,
      darken($secondary-light, 10%) 99%,
      darken($secondary-light, 10%) 100%
    );
    background-image: linear-gradient(
      to top,
      lighten($secondary-light, 10%) 0%,
      darken($secondary-light, 10%) 99%,
      darken($secondary-light, 10%) 100%
    );
  }
  
  .thin::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  /* mobile device */
  @include media-breakpoint-down(xs) {
    .scrollbar {
      height: 100% !important;
    }
  }
  