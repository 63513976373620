.btn {
    text-transform: unset;
    height: $btn-height;
    box-shadow: none;
    &-sm{
      height: $btn-height-sm;
    }
    &-floating {
      &.dropdown-toggle {
        &:after {
          display: none;
        }
      }
    }
    &-lg {
      height: $btn-height-lg;
    }
    &:disabled,
    &.disabled,
    fieldset:disabled & {
      box-shadow: none;
    }
  }
  .navbar-toggler {
    position: absolute;
    z-index: $zindex-popover;
    left: -15px;
    top: 15px;
  
  }
  
  a {
    cursor: pointer;
  }
  